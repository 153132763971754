import * as React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout";
import HeroHeader from "../components/HeroHeader";
import SubheaderLeft from "../components/SubheaderLeft";
import Pricelist from "../components/pricelist";
import Contact from "../components/Contact";
import { StaticImage } from "gatsby-plugin-image"
import LyziarskyKurzPodmienkyRezervacie from "../../static/files/LyziarskyKurzPodmienkyRezervacie.pdf"
import "./kurzy.css"



const LandingPage = () => {

    let subheader = {
        subheader: "Lyžiarska sezóna sa začína u nás",
        header: "Ponúkame požičanie:",
        text: [ <ul className="list-disc pl-4 pt-6">
                    <li>lyžiarskej výstroje</li>
                    <li>snowboardovej výstroje</li>
                    <li>bežkárskej výstroje</li>
                    <li>bigfoot</li>
                </ul>,
                <h5 className="pt-6 font-medium text-lg"> </h5>,
                <ul className="list-disc pl-4 pt-6">
                    <li>lyžiarska obuv a prilby - pripravené a 100% hygienicky čisté</li>
                </ul>,
        ],
    }



    return (
        <div>
            <Layout>
            <HeroHeader title="Lyžiarské kurzy">
                    <StaticImage placeholder="blurred" className="h-[400px]" objectFit="cover" objectPosition="50% 50%" height="400"  imgClassName="object-cover" src="../../static/images/headers/hero-header4.png" alt="skiers" />
                </HeroHeader>
                <SubheaderLeft data={subheader}>
                    <div className="hidden md:block"><StaticImage placeholder="blurred" imgClassName="" src="../../static/images/subheaders/subheader07.png" alt="skiers" /></div>
                    <div className="md:hidden block"><StaticImage placeholder="blurred"  imgClassName="" src="../../static/images/subheaders/subheader07-small.png" alt="skiers" /></div>
                </SubheaderLeft>
                <div className="mx-auto text-2xl text-center py-5 lg:px-40 font-medium ">
                    V prípade  neprevzatia výstroja  zákazníkom  v termíne  uvedenom  na  rezervačnom lístku, <br />sa rezervácia považuje za zrušenú a záloha 5 € prepadá  v prospech požičovne.<br />
                    Pri prevzatí sa záloha 5 € odpočíta od ceny požičania.<br />
                    <a href={LyziarskyKurzPodmienkyRezervacie} alt="Podmienky rezervácie a požičania výstroja na lyžiarsky zájazd." className="kurzy-links">Podmienky rezervácie a požičania výstroja na lyžiarsky zájazd na stiahnutie.</a><br />
                </div>                
                <Pricelist tags="kurzy" />
                <Contact></Contact>
            </Layout>
        </div>
    )
}

export const Head = () => <Seo title="Home" />

export default LandingPage